import React from 'react'

class Loader extends React.Component {
  render () {
    return (
        <div className="loader-bg">
            <div className="loader-track">
                <div className="loader-fill"/>
            </div>
        </div>
    );
  }
}

export default Loader;
