import { combineReducers } from 'redux';
import authentication from './authentication.reducer';
import insideReducer from './inside.reducer';
import alertReducer from './alert.reducer.js';

const rootReducer = combineReducers({
  authentication,
  insideReducer,
  alertReducer
});

export default rootReducer;
