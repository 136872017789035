import { alertConstants } from '../actions/auth.constants';

const alertReducer = (state = {}, {type,payload}) => {
  switch (type) {
    case alertConstants.ERROR_REQUEST:
      return {
        ...state,
      };
    case alertConstants.ERROR_SUCCESS:
      return {
        ...state,
        error:payload
      };
    case alertConstants.ERROR:
      return {
        ...state,
        error:payload
      };
    case alertConstants.ERROR_DONE:
      return {
        ...state,
      };
    default:
      return state
  }
}

export default alertReducer;
