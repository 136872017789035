import React, { Component,Suspense  } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import route from '../route';
import Loadable from 'react-loadable';
import Loader from './layout/Loader/';
import {PrivateRoute} from './PrivateRoute';
const Admin = Loadable({
  loader: () => import('./layout/Admin'),
  loading: Loader,
  delay:60
});

class App extends Component {
    render() {
      const menu = route.map((route, index) => {
          return (route.component) ? (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              name={route.name}
              render={props => (
                  <route.component {...props} />
              )} />
          ) : (null);

      });

      if(localStorage.getItem('healthcare_access_token')){

        return (
            <div>
              <Suspense fallback={<Loader/>}>
                <Switch>
                  <PrivateRoute exact path="*" component={Admin} />

                </Switch>
              </Suspense>
            </div>
        );
      } else {

        return (
          <div>
            <Suspense fallback={<Loader/>}>
              <Switch>
                {menu}
                <Redirect from="/" to='/' />
              </Switch>
            </Suspense>
          </div>
        )
      }
    }
}
export default App;
