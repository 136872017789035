import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App/index';
import { store } from './store';
import 'font-awesome/css/font-awesome.min.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import config from './config';
import {BrowserRouter} from 'react-router-dom';
import './assets/css/theme.css';
import {IntlProvider} from 'react-intl';
import ErrorBoundary from './errorBoundary'

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename={config.basename}>
      <IntlProvider locale='en'>
        <ErrorBoundary>
        <App />
        </ErrorBoundary>
        {/* <App /> */}
      </IntlProvider>
    </BrowserRouter>
  </Provider>, document.getElementById('root'));
