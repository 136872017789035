import { createStore,applyMiddleware } from 'redux';
import rootReducer from '../reducers';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
const loggerMiddleware = createLogger();

const initialState = {

};
export const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(
    thunk,
    loggerMiddleware
  )
);