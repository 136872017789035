import { insideConstants } from '../actions/auth.constants';

const insideReducer = (state = {}, {type,payload}) => {
  switch (type) {
    
    case insideConstants.VIEW_FILE_REQUEST:
      return {
        ...state
      };
    case insideConstants.VIEW_FILE_SUCCESS:
      return {
        ...state,
        viewFiles:payload
      };
    case insideConstants.DELETE_FILE:
      return {
        ...state,
        fileDeletion : {
          status: payload.status,
          message: payload.data.message
        }
      }
    case insideConstants.VIEW_FILE_REQUEST_DONE:
      return {
        ...state
      };
    case insideConstants.ADD_SAMPLE_REQUEST:
      return {
        ...state
      };
    case insideConstants.ADD_SAMPLE_REQUEST_SUCCESS:
      return {
        ...state,
        samples:payload
      };
    case insideConstants.ADD_SAMPLE_REQUEST_DONE:
      return {
        ...state
      };
    case insideConstants.SAMPLES_REQUEST:
      return {
        ...state
      };
    case insideConstants.SAMPLES_REQUEST_SUCCESS:
      return {
        ...state,
        samples:payload
      };
    case insideConstants.SAMPLES_REQUEST_DONE:
      return {
        ...state
      };
    case insideConstants.SAMPLES_REQUEST_DELETE:
      return {
        ...state,
        status:payload
      };
    case insideConstants.SAMPLES_REQUEST_DELETE_FAILURE:
      return {
        ...state,
        deleteSampleFail:payload
      };
    case insideConstants.ANALYSIS_REQUEST:
      return {
        ...state
      };
    case insideConstants.ANALYSIS_REQUEST_SUCCESS:
      return {
        ...state,
        analysis:payload
      };
    case insideConstants.ANALYSIS_REQUEST_DONE:
      return {
        ...state
      };
    case insideConstants.ADD_PROJECT:
      return {
        ...state
      };
    case insideConstants.ADD_PROJECT_SUCCESS:
      return {
        ...state,
        projects:payload
      };
    case insideConstants.ADD_PROJECT_DONE:
      return {
        ...state
      };
    case insideConstants.PROJECT_REQUEST:
      return {
        ...state
      };
    case insideConstants.PROJECT_REQUEST_SUCCESS:
      return {
        ...state,
        projects:payload
      };
    case insideConstants.PROJECT_REQUEST_DONE:
      return {
        ...state
      };
    case insideConstants.PROJECT_REQUEST_DELETE:
      return {
        ...state,
        status:payload
      };
    case insideConstants.PROJECT_ANALYSIS_REQUEST_SUCCESS:
        return {
          ...state,
          projectAnalysis:payload
        };
    case insideConstants.PROJECT_ANALYSIS_REQUEST_DONE:
        return {
          ...state
        };
    case insideConstants.PREQC_TABLE_ANALYSIS_REQUEST_SUCCESS:
          return {
            ...state,
            projectTableAnalysis:payload
          };
    case insideConstants.PREQC_TABLE_ANALYSIS_REQUEST_DONE:
          return {
            ...state
          };
    case insideConstants.ANALYSIS_TRIMMING_REQUEST:
        return {
          ...state
        };
    case insideConstants.ANALYSIS_TRIMMING_REQUEST_SUCCESS:
          return {
            ...state,
            Trimming:payload
          };
    case insideConstants.ANALYSIS_TRIMMING_REQUEST_DONE:
          return {
            ...state
          };

    case insideConstants.DUPLICATE_REMOVAL_REQUEST:
      return {
        ...state
      };
    case insideConstants.DUPLICATE_REMOVAL_REQUEST_SUCCESS:
      return {
        ...state,
        alignmentData:payload
      };
    case insideConstants.DUPLICATE_REMOVAL_REQUEST_DONE:
      return {
        ...state
      };

    case insideConstants.VARIANT_COLLECT_REQUEST:
      return {
        ...state
      };
    case insideConstants.VARIANT_COLLECT_REQUEST_SUCCESS:
      return {
        ...state,
        variantCollectData:payload
      };
    case insideConstants.VARIANT_COLLECT_REQUEST_DONE:
      return {
        ...state
      };
    case insideConstants.VARIANT_RAW_REQUEST:
      return {
        ...state
      };
    case insideConstants.VARIANT_RAW_REQUEST_SUCCESS:
      return {
        ...state,
        variantRawData:payload
      };
    case insideConstants.VARIANT_RAW_REQUEST_DONE:
      return {
        ...state
      };
    case insideConstants.PROJECT_SAMPLES_REQUEST:
        return {
          ...state
        };
    case insideConstants.PROJECT_SAMPLES_REQUEST_SUCCESS:
        return {
          ...state,
          projectSampleData:payload
        };
    case insideConstants.PROJECT_SAMPLES_REQUEST_DONE:
        return {
          ...state
        };
    case insideConstants.SAMTOOL_FLAGSTAT_REQUEST:
        return {
          ...state
        };
    case insideConstants.SAMTOOL_FLAGSTAT_REQUEST_SUCCESS:
        return {
          ...state,
          statMapFileData:payload
        };
    case insideConstants.SAMTOOL_FLAGSTAT_REQUEST_DONE:
        return {
          ...state
        };
    case insideConstants.STAT_VARIANT_FILE_REQUEST:
        return {
          ...state
        };
    case insideConstants.STAT_VARIANT_FILE_REQUEST_SUCCESS:
        return {
          ...state,
          statVarFileData:payload
        };
    case insideConstants.STAT_VARIANT_FILE_REQUEST_DONE:
        return {
          ...state
        };
    case insideConstants.CIRCOS_REQUEST:
        return {
          ...state
        };
    case insideConstants.CIRCOS_REQUEST_SUCCESS:
        return {
          ...state,
          circosData:payload
        };
    case insideConstants.CIRCOS_REQUEST_DONE:
        return {
          ...state
        };
    case insideConstants.WORLDMAP_REQUEST:
          return {
            ...state
          };
    case insideConstants.WORLDMAP_REQUEST_SUCCESS:
          return {
            ...state,
            worldMapData:payload
          };
    case insideConstants.WORLDMAP_REQUEST_DONE:
          return {
            ...state
          };
    case insideConstants.ADD_ANALYSIS_REQUEST:
        return {
          ...state
        };
    case insideConstants.ADD_ANALYSIS_REQUEST_SUCCESS:
        return {
          ...state,
          advanceAnalysisData:payload.data,
          newAnalysisStatus: payload.status
        };
    case insideConstants.ADD_ANALYSIS_REQUEST_DONE:
        return {
          ...state
        };
    case insideConstants.GENE_REQUEST:
          return {
            ...state
          };
    case insideConstants.GENE_REQUEST_SUCCESS:
          return {
            ...state,
            geneData:payload
          };
    case insideConstants.GENE_REQUEST_DONE:
          return {
            ...state
          };
    case insideConstants.GENE_DYNAMIC_WOLRD_REQUEST:
            return {
              ...state
            };
    case insideConstants.GENE_DYNAMIC_WOLRD_SUCCESS:
            return {
              ...state,
              genedynamiclWorldData:payload
            };
    case insideConstants.GENE_DYNAMIC_WOLRD_DONE:
            return {
              ...state
            };
    case insideConstants.SNPEFF_REQUEST:
            return {
              ...state
            };
    case insideConstants.SNPEFF_REQUEST_SUCCESS:
          return {
            ...state,
            snfeppdData:payload
          };
    case insideConstants.SNPEFF_DONE:
            return {
              ...state
            };
    case insideConstants.SUNBURST_REQUEST:
              return {
                ...state
              };
    case insideConstants.SUNBURST_REQUEST_SUCCESS:
          return {
              ...state,
              sunburstData:payload
            };
    case insideConstants.SUNBURST_DONE:
          return {
                ...state
              };
    case insideConstants.DATATABLE_REQUEST:
                return {
                  ...state
                };
    case insideConstants.DATATABLE_REQUEST_SUCCESS:
        return {
            ...state,
            dataTableDATA:payload
          };
    case insideConstants.DATATABLE_DONE:
        return {
            ...state
              };
    case insideConstants.DOCUMENT_REQUEST:
                return {
                  ...state
                };
    case insideConstants.DOCUMENT_REQUEST_SUCCESS:
        return {
            ...state,
            documentDATA:payload
          };
    case insideConstants.DOCUMENT_DONE:
        return {
            ...state
              };


    case insideConstants.COLLECT_HS_METRICS_REQUEST:
      return {
        ...state
      };
    case insideConstants.COLLECT_HS_METRICS_REQUEST_SUCCESS:
      return {
        ...state,
        collect_hs_metrics:payload
        };
    case insideConstants.COLLECT_HS_METRICS_REQUEST_DONE:
      return {
        ...state
      };

    case insideConstants.SOMATIC_STATS_METRICS_REQUEST:
      return {
        ...state
      };
    case insideConstants.SOMATIC_STATS_REQUEST_SUCCESS:
      return {
        ...state,
        somatic_stats: payload
      };
    case insideConstants.SOMATIC_STATS_REQUEST_DONE:
      return {
        ...state
      };

    case insideConstants.COLLECT_MULTIPLE_METRICS_REQUEST:
      return {
        ...state
      };
    case insideConstants.COLLECT_MULTIPLE_METRICS_REQUEST_SUCCESS:
      return {
        ...state,
        collect_multiple_metrics:payload
        };
    case insideConstants.COLLECT_MULTIPLE_METRICS_REQUEST_DONE:
      return {
        ...state
      };

    case insideConstants.COLLECT_QUALITY_YIELD_METRICS_REQUEST:
      return {
        ...state
      };
    case insideConstants.COLLECT_QUALITY_YIELD_METRICS_REQUEST_SUCCESS:
      return {
        ...state,
        collect_yield_metrics:payload
        };
    case insideConstants.COLLECT_QUALITY_YIELD_METRICS_REQUEST_DONE:
      return {
        ...state
      };

    case insideConstants.DOC_REQUEST:
      return {
        ...state
      };
    case insideConstants.DOC_REQUEST_SUCCESS:
      return {
        ...state,
        doc:payload
      };
    case insideConstants.DOC_REQUEST_DONE:
      return {
        ...state
      };

    case insideConstants.VCF_FILE_GENERATOR_REQUEST:
      return {
        ...state
      };
    case insideConstants.VCF_FILE_GENERATOR_REQUEST_SUCCESS:
      return {
        ...state,
        vcf_file_data:payload
        };
    case insideConstants.VCF_FILE_GENERATOR_REQUEST_DONE:
      return {
        ...state
      };

    case insideConstants.DOCUMENT_SEARCH_REQUEST:
                return {
                  ...state
                };
    case insideConstants.DOCUMENT_SEARCH_REQUEST_SUCCESS:
        return {
            ...state,
            documentSearchDATA:payload
          };
    case insideConstants.DOCUMENT_SEARCH_DONE:
        return {
            ...state
              };

    case insideConstants.ANALYSIS_DETAILS_REQUEST:
      return {
        ...state
      }
    case insideConstants.ANALYSIS_DETAILS_REQUEST_SUCCESS:
      return{
        ...state,
        analysisDetails:payload
      }
    case insideConstants.ANALYSIS_DETAILS_REQUEST_DONE:
      return{
        ...state
      }
      //
    case insideConstants.DASH_ADVANCE_ANALYSIS_REQUEST:
      return {
        ...state
      }
    case insideConstants.DASH_ADVANCE_ANALYSIS_REQUEST_SUCCESS:
      return{
        ...state,
        advanceAnalysisDashData:payload
      }
    case insideConstants.DASH_ADVANCE_ANALYSIS_REQUEST_DONE:
      return{
        ...state
      }
      case insideConstants.SET_ADVANCE_ANALYSIS_RENDER_TAG:
        return{
          ...state,
          is_advance_analysis_rendered: payload
        }
        case insideConstants.NOTIFICATION_REQUEST:
        return{
          ...state
        }
        case insideConstants.NOTIFICATION_REQUEST_SUCCESS:
        return{
          ...state,
          notifications: payload
        }
        case insideConstants.NOTIFICATION_REQUEST_DONE:
        return{
          ...state
        }
        case insideConstants.SPOT_INSTANCE_REQUEST_STATE:
        return{
          ...state,
          spotInstanceRequestState: payload
        }
        case insideConstants.USER_FTP_PASSWORD:
        return{
          ...state,
          userFtpPassword: payload
        }
        case insideConstants.ELASTIC_INDEX_DETAILS:
          return{
            ...state,
            elasticSearch: payload
          }
    default:
      return state
  }
}
export default insideReducer;
