import { authConstants } from '../actions/auth.constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case authConstants.LOGIN_SUCCESS:
      return {
        loggedIn:true,
        next:action.user
      }
    case authConstants.LOGIN_FAILURE:
      return {
        loggedIn: false,
        error:action.error
      };
    case authConstants.LOGOUT:

      return {
          loggedIn:false,
          logoutUrl:action.user
      };
    case authConstants.SIGNUP_REQUEST:
      return {
        'request':action
      }
    case authConstants.SIGNUP_SUCCESS:
      return {
        'message':action.user.message
      }
    case authConstants.SIGNUP_FAILURE:
      return {
        error:action.error
      }
    default:
    return state
  }
}
export default authentication;
